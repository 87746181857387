import axios from "axios";
import { AuthActionType } from "../actionTypes";


const searchObj = {
  search: {
    location: {},
    activity: {}
  },
  result: {}
}

const SearchAction = (locationState, activityState, history) => {
  return async (dispatch) => {
    try {
      searchObj.search.location = locationState;
      searchObj.search.activity = activityState;
      const data = await axios.post("/search", searchObj.search);
      searchObj.result = data.data;
      dispatch({ type: AuthActionType.SEARCH_SUCCESS, payload: searchObj});
      history.push('/search')
    } catch (error) {
      dispatch({ type: AuthActionType.SEARCH_FAIL, payload: searchObj });
      history.push('/search')
    }
  };
};

export default SearchAction;