import axios from "axios";
import { AuthActionType } from "../actionTypes";

const SettingAuthAction = (formData) => {
  return async (dispatch) => {
    try {
      const data = await axios.post("/profile", formData);
      dispatch({ type: AuthActionType.AUTH_SETTING_SUCCESS, payload: data});
    } catch (error) {
      dispatch({ type: AuthActionType.AUTH_SETTING_FAIL, payload: {} });
    }
  };
};

export default SettingAuthAction;