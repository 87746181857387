// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import React, { useEffect, useState } from "react";
import InfoNavbar from "components/InfoNavbar.js";
import FooterWhite from "components/FooterWhite.js";

function EmailTest2() {

  console.log("Complete POST");
  
   const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [message,setMessage] = useState("")
    const [subject,setSubject] = useState("")
    const PostData = ()=>{
        fetch("https://api-l4jvlsqsvq-ew.a.run.app/send",{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                name,
                email,
                subject,
                message
            })
        }).then(res=>res.json())
        .then(data=>{
            setMessage('')
            setName('')
            setSubject('')
            setEmail('')
        }).catch(err=>{
            console.log(err)
        })
    }  

  return (
    <>
      <InfoNavbar />
      <div className="wrapper">
        <div className="main searchpage-1 $background-lighter-grey">
          <Container>
            <h1>Email Test 2</h1>
            <br />
              <div style={{maxWidth: '60%', display:'flex', flexDirection: 'column', alignItems:'center'}} className="mycard">
                <div className="card auth-card input-field">
                  <h2>Send me a Message</h2>
                  <input
                  type="text"
                  placeholder="email"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                  <input
                  type="text"
                  placeholder="name"
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                  />
                  <input
                  type="text"
                  placeholder="subject"
                  value={subject}
                  onChange={(e)=>setSubject(e.target.value)}
                  />
                  <textarea
                  type="text"
                  placeholder="Message"
                  value={message}
                  onChange={(e)=>setMessage(e.target.value)}
                  />
                  <button className="btn waves-effect waves-light #64b5f6 blue darken-1"
                  onClick={()=>PostData()}
                  >
                      Send Message
                  </button>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}


export default EmailTest2;
