import axios from "axios";
import { AuthActionType } from "../actionTypes";

const authState = {
  isLoggedIn: false,
  user: {
    _id: "",
    firstName: "",
    lastName: "",
    jwt: "",
    exp: ""
  }
}

//const jwtLocalStorage = {
//  getAuthState: () => {
  const getAuthState = () => {
    const auth = localStorage.getItem("outdoor_auth");
    try {
      const authObj = JSON.parse(auth);
      const {jwt, exp} = authObj.user;
      if ( exp > Date.now()/1000 ) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + jwt;
        return authObj;
      }
      return authState;
    }
    catch {
      return authState;
    }
  }
//}

const newAuth = getAuthState();

const authreducer = (state = newAuth, action) => {
  switch (action.type) {
    case AuthActionType.AUTH_REQUEST_SUCCESS:
      return {
        isLoggedIn: false,
        user: action.payload.data
      }
    case AuthActionType.AUTH_SUCCESS:
    case AuthActionType.AUTH_SIGNIN_SUCCESS:
    case AuthActionType.AUTH_SETTING_SUCCESS:
      const newAuthState = {
        isLoggedIn: true,
        user: action.payload.data
      };
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + action.payload.jwt;
      localStorage.setItem("outdoor_auth", JSON.stringify(newAuthState));
      return newAuthState;
    case AuthActionType.AUTH_CONTACT_SUCCESS:
    case AuthActionType.AUTH_REVIEW_SUCCESS:
      return newAuth;
    case AuthActionType.AUTH_REQUEST_FAIL:
    case AuthActionType.AUTH_SIGNIN_FAIL:
    case AuthActionType.AUTH_FAIL:
    case AuthActionType.AUTH_LEAVE:
    case AuthActionType.AUTH_SETTING_FAIL:
    case AuthActionType.AUTH_CONTACT_FAIL:
    case AuthActionType.AUTH_REVIEW_FAIL:
      axios.defaults.headers.common["Authorization"] = '';
      localStorage.setItem("outdoor_auth", JSON.stringify(authState));
      return authState;
    default:
      return state;
  }
}

export default authreducer;
