const AuthActionType = {
  AUTH_REQUEST_SUCCESS: "AUTH_REQUEST_SUCCESS",
  AUTH_REQUEST_FAIL: "AUTH_REQUEST_SUCCESS",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_FAIL: "AUTH_FAIL",
  AUTH_SIGNIN_SUCCESS: "AUTH_SIGNIN_SUCCESS",
  AUTH_SIGNIN_FAIL: "AUTH_SIGNIN_FAIL",
  AUTH_LEAVE: "AUTH_LEAVE",
  AUTH_SETTING_SUCCESS: "AUTH_SETTING_SUCCESS",
  AUTH_SETTING_FAIL: "AUTH_SETTING_FAIL",
  AUTH_CONTACT_SUCCESS: "AUTH_CONTACT_SUCCESS",
  AUTH_CONTACT_FAIL: "AUTH_CONTACT_FAIL",
  AUTH_REVIEW_SUCCESS: "AUTH_REVIEW_SUCCESS",
  AUTH_REVIEW_FAIL: "AUTH_REVIEW_FAIL",
  SEARCH_SUCCESS: "SEARCH_SUCCESS",
  SEARCH_FAIL: "SEARCH_FAIL",
}

export { AuthActionType };
