import axios from "axios";
import { AuthActionType } from "../actionTypes";

const ContactAuthAction = (formData, userId) => {
  return async (dispatch) => {
    try {
      const data = await axios.post("/contact/" + userId, formData);
      dispatch({ type: AuthActionType.AUTH_CONTACT_SUCCESS, payload: data});
    } catch (error) {
      dispatch({ type: AuthActionType.AUTH_CONTACT_FAIL, payload: {} });
    }
  };
};

export default ContactAuthAction;