import axios from "axios";
import { AuthActionType } from "../actionTypes";

const LoginRequestAuthAction = (userState) => {
  return async (dispatch) => {
    try {
      const data = await axios.post("/loginrequest", userState);
      dispatch({ type: AuthActionType.AUTH_REQUEST_SUCCESS, payload: data});
    } catch (error) {
      dispatch({ type: AuthActionType.AUTH_REQUEST_FAIL, payload: {} });
    }
  };
};

export default LoginRequestAuthAction;