/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function LandingFooterWhite() {
  return (
    <>
      <footer id="new-footer" className="footer">
        <Container className="footer-inner">
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="/terms-and-conditions"
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()}
                , Made with <i className="fa fa-heart heart" /> by The Outdoor Network.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default LandingFooterWhite;
