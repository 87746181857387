// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import InfoNavbar from "components/InfoNavbar.js";
import FooterWhite from "components/FooterWhite.js";

function Privacy() {

  return (
    <>
      <InfoNavbar />
      <div className="wrapper">
        <div className="main searchpage-1 $background-lighter-grey">
          <Container>
            <h1>Privacy Policy</h1>
            <p><strong>THE OUTDOOR NETWORK LTD</strong></p>

			<h3>Our contact details</h3>
			<p>&nbsp;</p>
			<p>Name: THE OUTDOOR NETWORK LTD</p>
			<p>E-mail: info@theoutdoornetwork.co.uk</p>
			
			<h3>The type of personal information we collect </h3>
			<p>&nbsp;</p>
			<p>We currently collect and process the following information:</p>
			<ul>
			<li>Full Name</li>
			<li>Location</li>
			<li>Email</li>
			<li>Website user stats</li>
			<li>Qualifications</li>
			</ul>

			<h3>How we get the personal information and why we have it</h3>
			<p>&nbsp;</p>
			<p>Most of the personal information we process is provided to us directly by you for one of the following reasons:</p>
			<ul>
			<li>To be able match job opportunity that are relevant to you location and skill aet.</li>
			<li>For companies to search for your skills and services and contact you through our platform.</li>
			</ul>
			<p>We use the information that you have given us in order to provide quality service to you and the companies that are looking for you services.</p>
			<p>We may share this information with information with companies that wish to use your services, however this is done by expressed permission from you.</p>
			<p>Under the UK General Data Protection Regulation (UK GDPR), the lawful bases we rely on for processing this information are:</p>
			<p><strong>Your consent. You are able to remove your consent at any time. You can do this by contacting <a href="mailto:info@theoutdoornetwork.co.uk">info@theoutdoornetwork.co.uk</a></strong></p>

			<h3>How we store your personal information</h3>
			<p>&nbsp;</p>
			<p>Your information is securely stored.</p>
			<p>We keep name and emails for 12 months from inactivity. We will then dispose your information by removing all records and your information from our system.</p>
			<p><strong>Your data protection rights</strong></p>
			<p>Under data protection law, you have rights including:</p>
			<p><strong>Your right of access</strong> - You have the right to ask us for copies of your personal information.</p>
			<p><strong>Your right to rectification</strong> - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</p>
			<p><strong>Your right to erasure</strong> - You have the right to ask us to erase your personal information in certain circumstances.</p>
			<p><strong>Your right to restriction of processing</strong> - You have the right to ask us to restrict the processing of your personal information in certain circumstances.</p>
			<p><strong>Your right to object to processing</strong> - You have the the right to object to the processing of your personal information in certain circumstances.</p>
			<p><strong>Your right to data portability</strong> - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</p>
			<p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</p>
			<p>Please contact us at <a href="mailto:info@theoutdoornetwork.co.uk">info@theoutdoornetwork.co.uk</a> if you wish to make a request.</p>

			<h3>How to complain</h3>
			<p>&nbsp;</p>
			<p>If you have any concerns about our use of your personal information, you can make a complaint to us at <a href="mailto:info@theoutdoornetwork.co.uk">info@theoutdoornetwork.co.uk</a></p>
			<p>You can also complain to the ICO if you are unhappy with how we have used your data.</p>
			<p>The ICOs address:</p>
			<p>Information Commissioners Office</p>
			<p>Wycliffe House</p>
			<p>Water Lane</p>
			<p>Wilmslow</p>
			<p>Cheshire</p>
			<p>SK9 5AF</p>
			<p>&nbsp;</p>
			<p>Helpline number: 0303 123 1113</p>
			<p>ICO website: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></p>
			<p>&nbsp;</p>
			<p>A PDF version of the Privacy Policy can be downloaded <a href="/ton_privacy.pdf" target="_blank">here</a>.</p>
			<p>If you need a PDF reader, you can download Adobe Reader for free.</p>
			<p><a href="https://get.adobe.com/uk/reader" target="_blank">https://get.adobe.com/uk/reader</a></p>
			<p>&nbsp;</p>
            <a href="./Index.js">Take me home</a>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}


export default Privacy;
