import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from "./redux/store"

import App from './App'
import axios from 'axios'

// axios.defaults.baseURL = "http://localhost:8080";
axios.defaults.baseURL = "https://api-l4jvlsqsvq-ew.a.run.app";
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,

  document.getElementById('root')
)
