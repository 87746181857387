import { AuthActionType } from "../actionTypes";

const searchState = {
  search: {
    location: {},
    activity: {}
  },
  result: {}
}

const getSearchState = () => {
  const search = localStorage.getItem("outdoor_search");
  if (search){
    try {
      const searchObj = JSON.parse(search);
      return searchObj;
    }
    catch {
      return searchState;
    }
  }
  return searchState;
}


const newSearch = getSearchState();

const searchReducer = (state = newSearch, action) => {
  switch (action.type) {
    case AuthActionType.SEARCH_SUCCESS:
      state = action.payload;
      localStorage.setItem("outdoor_search", JSON.stringify(state));
      return state;
    case AuthActionType.SEARCH_FAIL:
      state = action.payload;
      localStorage.setItem("outdoor_search", JSON.stringify(state));
      return state;
    default:
      return state;
  }
}

export default searchReducer;
