import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import ContactAuthAction from "../redux/actions/ContactAuthAction";
import ReviewAuthAction from "../redux/actions/ReviewAuthAction";
import defaultAvatar from "assets/img/placeholder.jpg";
import { Rating } from "react-simple-star-rating";

// reactstrap components
import {
  Button,
  Form,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";

// core components
import ColorNavbar from "components/ColorNavbar.js";
import ProfilePageHeader from "components/ProfilePageHeader.js";
import FooterWhite from "components/FooterWhite.js";

function ProfilePage(props) {
  const { states, contact, review } = props;
  const auth = states.auth;
  const { userId } = useParams();
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultAvatar);
  const [contactModal, setContactModal] = React.useState(false);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [contactState, setContactState] = useState({ message: "" });
  const [reviewState, setReviewState] = useState({ rating: "", comment: "" });
  const [profileState, setProfileState] = useState({
    _id: "",
    firstName: "",
    avatar: false,
    hero: false,
    lastName: "",
    title: "",
    company: false,
    companyName: "",
    description: "",
    activity: [],
    location: [],
  });
  const [reviewsState, setReviewsState] = useState([
    {
      reviewer: "",
      user: "",
      comment: "",
      rating: "",
    },
  ]);
  const [activeTab, setActiveTab] = React.useState("3");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    async function fetchProfile() {
      const result = await axios.get("/profile/" + userId);
      setProfileState(result.data);
    }
    async function fetchReview() {
      const result = await axios.get("/review/" + userId);
      setReviewsState(result.data);
    }
    fetchProfile(userId);
    fetchReview(userId);
  }, [userId]);

  useEffect(() => {
    if (profileState.avatar) {
      const url =
        "https://storage.googleapis.com/cloud-storage-ton/" +
        userId +
        "-avatar.jpg?" +
        (Math.random() + 1).toString(36).substring(7);
      setImagePreviewUrl(url);
    }
  }, [profileState.avatar, userId]);

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  function Contact() {
    if (auth.isLoggedIn) {
      return (
        <Button
          className="btn btn-info profileactionbutton"
          color="default"
          onClick={() => setContactModal(true)}
        >
          Contact Freelancer
        </Button>
      );
    } else {
      return (
        <Button className="btn btn-info profileactionbutton" color="default" disabled={true}>
          Login To Contact Freelancer
        </Button>
      );
    }
  }

  function Review() {
    if (auth.isLoggedIn && !profileState.company) {
      return (
        <Button
          className="btn btn-info profileactionbutton"
          color="default"
          onClick={() => setReviewModal(true)}
        >
          Create Review
        </Button>
      );
    } else {
      return (
        <Button className="btn btn-info profileactionbutton" color="default" disabled={true}>
          Login To Create Review
        </Button>
      );
    }
  }

  function LocationList() {
    if (
      profileState.location &&
      profileState.location.length &&
      !profileState.company
    ) {
      var rows = [];
      for (var i = 0; i < profileState.location.length; i++) {
        rows.push(<li key={i}>{profileState.location[i].label}</li>);
        rows.push(<hr />);
      }
      return <ul className="list-unstyled follows">{rows}</ul>;
    } else {
      return <h3>Unfortunately nothing to show yet...</h3>;
    }
  }

  function ActivityList() {
    if (
      profileState.activity &&
      profileState.activity.length &&
      !profileState.company
    ) {
      var rows = [];
      for (var i = 0; i < profileState.activity.length; i++) {
        rows.push(<li key={i}>{profileState.activity[i].label}</li>);
        rows.push(<hr />);
      }
      return <ul className="list-unstyled follows">{rows}</ul>;
    } else {
      return <h3>Unfortunately nothing to show yet...</h3>;
    }
  }

  function ReviewsList() {
    if (reviewsState.length && !profileState.company) {
      var rows = [];
      for (var i = 0; i < reviewsState.length; i++) {
        rows.push(
          <Rating
            size={20}
            initialValue={reviewsState[i].rating}
            readonly={true}
          />
        );
        rows.push(<li key={i}>{reviewsState[i].comment}</li>);
        rows.push(
          <Link to={"/profile/" + reviewsState[i].reviewer}>
            See reviewer's profile
          </Link>
        );
        rows.push(<hr />);
      }
      return <ul className="list-unstyled follows">{rows}</ul>;
    } else {
      return <h3>Unfortunately nothing to show yet...</h3>;
    }
  }

  return (
    <>
      <ColorNavbar />
      <ProfilePageHeader id={profileState._id} hero={profileState.hero} />
      <div className="wrapper">
        <div className="profile-content section">
          <Container>
            <Row>
              <div className="profile-picture">
                <div
                  className="fileinput fileinput-new"
                  data-provides="fileinput"
                >
                  <div className="fileinput-new img-no-padding">
                    <img style={{objectFit: "contain"}} alt="..." src={imagePreviewUrl} />
                  </div>
                  <div className="name">
                    <h4 className="title text-center">
                      {profileState.firstName} {profileState.lastName}
                      <br />
                      <small>
                        {!profileState.company ? (
                          <Rating
                            size={20}
                            initialValue={profileState.rating}
                            readonly={true}
                          />
                        ) : (
                          ""
                        )}
                        {profileState.company && profileState.companyName
                          ? profileState.companyName
                          : ""}
                      </small>
                    </h4>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div className="profile-picture" style={{textAlign:"center"}}>
                <h6 style={{textAlign:"center"}}></h6>
                <p><strong>Bio: </strong>{profileState.description}</p>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <br />
                <Contact />
                
                {profileState.company ? "" : <Review />}
              </Col>
            </Row>
            <br />
            {profileState.company ? (
              ""
            ) : (
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <br />
                  <Nav role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Available locations
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === "2" ? "active" : ""}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Qualificications
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === "3" ? "active" : ""}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Reviews
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
            )}
            {/* Tab panes */}
            {profileState.company ? (
              ""
            ) : (
              <TabContent className="following" activeTab={activeTab}>
                <TabPane tabId="1" id="locations">
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <LocationList />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2" id="activities">
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <ActivityList />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3" id="reviews">
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <ReviewsList />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            )}
            <Modal
              isOpen={contactModal}
              toggle={() => setContactModal(false)}
              className="modal-register"
            >
              <div className="modal-header no-border-header text-center">
                <button
                  className="close"
                  type="button"
                  onClick={() => setContactModal(false)}
                >
                  <span>×</span>
                </button>
                <h6 className="text-muted margin50">Contact</h6>
                <h3 className="modal-title text-center">
                  Send {profileState.firstName} a message asking their
                  availability
                </h3>
                <p>
                  Ask them if they are available and let them know about the
                  work you have available
                </p>
              </div>
              <div className="modal-body">
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    console.log("page:" + contactState + " - " + userId);
                    contact(contactState, userId);
                    setContactModal(false);
                  }}
                >
                  <FormGroup>
                    <Input
                      placeholder="Enter your message here"
                      className="textarea-limited"
                      type="textarea"
                      rows="3"
                      value={contactState.message}
                      onChange={(event) => {
                        const message = event.target.value;
                        setContactState({ ...contactState, ...{ message } });
                      }}
                    />
                    <br />
                    <Button block className="btn btn-info" color="default">
                      Send
                    </Button>
                  </FormGroup>
                </Form>
              </div>
              <div className="modal-footer no-border-footer">
                <span className="text-muted text-center">
                  You'll respond to eachother via email. Please note that after this message, you will both have each other's email addresses, which you can make future contact using your own email provider.
                </span>
              </div>
            </Modal>
            <Modal
              isOpen={reviewModal}
              toggle={() => setReviewModal(false)}
              className="modal-register"
            >
              <div className="modal-header no-border-header text-center">
                <button
                  className="close"
                  type="button"
                  onClick={() => setReviewModal(false)}
                >
                  <span>×</span>
                </button>
                <h6 className="text-muted">Review</h6>
                <h4 className="modal-title text-center">
                  {" "}
                  Leave a review for {profileState.firstName}{" "}
                  {profileState.lastName}
                </h4>
                <p>
                  Rate your experience with {profileState.firstName} from 1 - 5
                </p>
              </div>
              <div className="modal-body">
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    console.log("page:" + reviewState + " - " + userId);
                    review(reviewState, userId);
                    setReviewModal(false);
                  }}
                >
                  <FormGroup>
                    <Input
                      placeholder="1-5"
                      type="number"
                      min="1"
                      max="5"
                      value={reviewState.rating}
                      onChange={(event) => {
                        const rating = event.target.value;
                        setReviewState({ ...reviewState, ...{ rating } });
                      }}
                    />

                    <Input
                      placeholder="This is a textarea limited to 1500 characters."
                      className="textarea-limited"
                      type="textarea"
                      maxLength="1500"
                      rows="3"
                      value={reviewState.comment}
                      onChange={(event) => {
                        const comment = event.target.value;
                        setReviewState({ ...reviewState, ...{ comment } });
                      }}
                    />
                    <br />
                    <Button block className="btn btn-info" color="default">
                      Send
                    </Button>
                  </FormGroup>
                </Form>
              </div>
              <div className="modal-footer no-border-footer">
                <span className="text-muted text-center">
                  Every user can leave only one review,
                  <br />
                  but you can always update it here later.
                </span>
              </div>
            </Modal>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    states: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    contact: (contactState, userId) => {
      dispatch(ContactAuthAction(contactState, userId));
    },
    review: (reviewState, userId) => {
      dispatch(ReviewAuthAction(reviewState, userId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
