import axios from "axios";
import { AuthActionType } from "../actionTypes";

const SigninRequestAuthAction = (userState, history) => {
  return async (dispatch) => {
    try {
      const data = await axios.post("/signin", userState);
      dispatch({ type: AuthActionType.AUTH_SIGNIN_SUCCESS, payload: data});
      history.push('/settings')
    } catch (error) {
      dispatch({ type: AuthActionType.AUTH_SIGNIN_SUCCESS, payload: {} });
      history.push('/error')
    }
  };
};

export default SigninRequestAuthAction;