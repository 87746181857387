import React, {useEffect} from "react";
import axios from "axios";
import FormData from "form-data";
import { Button } from "reactstrap";

import defaultHeader from "assets/img/sections/joshua-earles.jpg";

function SettingsHeader(props) {
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState( defaultHeader);
  const fileInput = React.useRef(null);

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleImageChange = async (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      let reader = new FileReader();
      let file = e.target.files[0];
      if (file.type === "image/jpeg" && file.size <= 50 * 1024 * 1024) {
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      try {
        const form = new FormData();
        form.append('file', file);
        const url = "/image/hero/" + props.id;
        axios.post(url, form, { headers: {
          'Content-Type': 'multipart/form-data'
        }});
        let outdoor_auth = localStorage.getItem("outdoor_auth");
        let outdoor_auth_obj = JSON.parse(outdoor_auth)
        outdoor_auth_obj.user.avatar = true;
        localStorage.setItem("outdoor_auth", JSON.stringify(outdoor_auth_obj));
      }
      catch{}
      }
      else {
        window.alert("Only .jpg images under 50mb.");
      }
    }
  };

  useEffect(() => {
    if(props.hero) {
      const url = "https://storage.googleapis.com/cloud-storage-ton/" + props.id + "-hero.jpg?" + (Math.random() + 1).toString(36).substring(7);
      setImagePreviewUrl(url);
    }
  }, [props.hero, props.id]);

  return (
    <>
    <input type="file" onChange={handleImageChange} ref={fileInput} style={{display: 'none'}}/>
      <div
        className="page-header page-header-xs settings-background"
        style={{
          backgroundImage:
            "url(" +
            imagePreviewUrl +
            ")",
        }}
      >
        <div>
      </div>
        <div className="filter" />
      </div>
      <Button
          className="btn-round"
          color="white"
          outline
          onClick={handleClick}
          style={{marginTop: "-85px", marginLeft: "10px", zIndex: "1", position: "relative"}}
        >
          {imagePreviewUrl === defaultHeader ? "Add Cover Photo" : "Change Cover Photo"}
        </Button>

    </>
  );
}

export default SettingsHeader;
