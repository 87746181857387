// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import InfoNavbar from "components/InfoNavbar.js";
import FooterWhite from "components/FooterWhite.js";

function EmailTest() {

  console.log("The EmailTest fuction is now being called.");

  var nodemailer = require('nodemailer');

  let transporter = nodemailer.createTransport({
    host: "mail.gandi.net",
    port: 587,
    secure: true, // use TLS (Using StartTLS)
    auth: {
      user: "theoutdoornetwork@advertise1clients.uk",
      pass: "NewMountain2023",
    },
  });

  let mailOptions = {
    from: '"Example Team" <info@theoutdoornetwork.co.uk>',
    to: 'hello@advertise1.uk, jonathon819672@gmail.com',
    subject: 'Nice Nodemailer test',
    text: 'Hey there, its our first message sent with Nodemailer',
    html: '<b>Hey there! </b> This is our first message sent with Nodemailer<br />'
  };

  transporter.sendMail(mailOptions, (error, info) => {
    if (error) {
      return alert(error);
    }
    alert('Message sent: %s', info.messageId);
  });

  alert("Complete");

  return (
    <>
      <InfoNavbar />
      <div className="wrapper">
        <div className="main searchpage-1 $background-lighter-grey">
          <Container>
            <h1>Email Test</h1>
            <br />
            <p>This is a email test file, function file added</p>
            <br />
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}


export default EmailTest;
