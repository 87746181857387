const activities = [
  { value: "WAL-MT-IML", label: "International Mountain Leader (IML)" },
  { value: "WAL-MT-WML", label: "Winter Mountain Leader (WML)" },
  { value: "WAL-MT-ML", label: "Summer Mountain Leader (ML)" },
  { value: "WAL-MT-HaM", label: "Hill and Moorland" },
  { value: "WAL-MT-LL", label: "Lowland Leader" },
  { value: "ROP-MT-WMaCI", label: "Winter Moutaineering & Climbing Instructor" },
  { value: "ROP-MT-MaCI", label: "Moutaineering & Climbing Instructor" },
  { value: "ROP-MT-SPA", label: "Single Pitch Award (SPA)" },
  { value: "ROP-MT-RCI", label: "Rock Climbing Instructor" },
  { value: "ROP-ERCA-HRI", label: "ERCA High Ropes Instructor (TradRC)" },
  { value: "ROP-ERCA-LRI", label: "ERCA Low Ropes Instructor (TradRC)" },
  { value: "ROP-IRATA-RAL1", label: "IRATA Rope Access Level 1" },
  { value: "ROP-IRATA-RAL2", label: "IRATA Rope Access Level 2" },
  { value: "ROP-IRATA-RAL3", label: "IRATA Rope Access Level 3" },
  { value: "COA-NCCNWSFAALA-CG", label: "NCC, NWSF, AALA Coasteering Guide" },
  { value: "CAN-UKCG-L1G", label: "UKCG Level 1 Guide" },
  { value: "CAN-UKCG-L2G", label: "UKCG Level 2 Guide" },
  { value: "CAN-UKCG-L3G", label: "UKCG Level 3 Guide" },
  { value: "CAN-UKCG-L4G", label: "UKCG Level 4 Guide" },
  { value: "CAN-UKCG-L5G", label: "UKCG Level 5 Guide" },
  { value: "CAV-BCA-CL", label: "BCA Cave Leader" },
  { value: "CAV-BCA-ML", label: "BCA Mine Leader" },
  { value: "CAV-BCA-CaML", label: "BCA Cave & Mine Leader" },
  { value: "PAD-BCU-PI", label: "BCU Paddlesport Instructor" },
  { value: "PAD-BCU-PL", label: "BCU Paddlesport Leader" },
  { value: "PAD-BCU-L23T", label: "BCU Level 2 + 3* Touring (Old Framework)" },
  { value: "PAD-BCU-L23WW", label: "BCU Level 2 + 3* White Water (Old Framework)" },
  { value: "PAD-BCU-L3K", label: "BCU Level 3 Kayak (Old Framework)" },
  { value: "PAD-BCU-L3C", label: "BCU Level 3 Canoe (Old Framework)" },
  { value: "PAD-BCU-FSaRT", label: "BCU Foundation Safety and Rescue Training" },
  { value: "PAD-BCU-WWSaRT", label: "BCU White Water Safety and Rescue Training" },
  { value: "PAD-BSUPA-L1SUP", label: "BSUPA Level 1 instructor SUP" },
  { value: "PAD-BSUPA-L2SUP", label: "BSUPA Level 2 instructor SUP" },
  { value: "PAD-BSUPA-C", label: "BSUPA Coach" },
  { value: "PAD-BSUPA-TE", label: "BSUPA Trainer/Examiner" },
  { value: "POB-RYA-PBI", label: "RYA Power Boat Instructor (PBI)" },
  { value: "POB-RYA-PBL2C", label: "RYA Power Boat Level 2 Coastal" },
  { value: "POB-RYA-PBL2I", label: "RYA Power Boat Level 2 Inland" },
  { value: "POB-RYA-PBL2", label: "RYA Power Boat Level 2" },
  { value: "POB-RYA-SB", label: "RYA Safety Boat" },
  { value: "POB-RYA-ICoC", label: "RYA International Certificate of Competence" },
  { value: "SAI-RYA-SDI", label: "RYA Sailing Instuctor (SDI)" },
  { value: "SAI-RYA-DI", label: "RYA Dinghy Instuctor (DI)" },
  { value: "SAI-RYA-ADI", label: "RYA Assistant Dinghy Instructor (ADI)" },
  { value: "WIN-RYA-SI", label: "Windsurfing Level 1 - RYA" },
  { value: "WIN-RYA-SWI", label: "Windsurfing Level 2 - RYA" },
  { value: "WIN-RYA-AWI", label: "Windsurfing Level 3 - RYA" },
  { value: "ONC-AGB-IA", label: "Archery GB Instructor Award" },
  { value: "ONC-BF-I", label: "Fencing Instructor" },
  { value: "ONC-EASiR-ETL1S", label: "EASi Rider Enclosed Track Level 1 Supervisor" },
  { value: "ONC-EASiR-L2T", label: "EASi Rider Level 2 Trekking" },
  { value: "TB-TBE", label: "Team building events" },
  { value: "TB-PS", label: "Problem Solving" },
  { value: "TB-RB", label: "Raft Build" },
  { value: "TB-IPS", label: "Indoor Problem Solving" },
  { value: "LIG-RLSS-NPLQ", label: "RLSS National Pool Lifeguard Qualification" },
  { value: "LIG-RLSS-NVBLQ", label: "RLSS National Vocational Beach Lifeguard Qualification" },
  { value: "LIG-RLSS-OWLQ", label: "RLSS Open Water Lifegaurd Qualification" },
  { value: "LIG-RLSS-ILQ", label: "RLSS International Lifeguard Qualification" },
  { value: "DOE-AW", label: "D of E Award Verifier" },
  { value: "DOE-EAaS", label: "D of E Expedition Assessor and Supervisor" }]

  export default activities;