// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import InfoNavbar from "components/InfoNavbar.js";
import FooterWhite from "components/FooterWhite.js";

function Terms() {

  return (
    <>
      <InfoNavbar />
      <div className="wrapper">
        <div className="main searchpage-1 $background-lighter-grey">
          <Container>
            <br />
            <h1>Terms and Conditions</h1>
            <br />
            <p><br /></p>
            <p>Policy Created: 02/08/22</p>
            <p>Policy Updated: 16/12/22</p>
            <p><br /></p>
            <p>The following terms and conditions apply to all visitors and users of this website. By accessing this web site, visitors and users acknowledge acceptance of these terms and conditions.</p>
            <p><br /></p>
            <p>The owners reserve the right to change these terms and conditions at their sole discretion without notice to users.</p>
            <p><br /></p>
            <p>In the case of any violation of these terms and conditions, the owners reserve the right to seek all remedies available by law and in equity for such violations.</p>
            <p><br /></p>
            <p>Copyright Statement</p>
            <p><br /></p>
            <p>All contents on this site are protected by copyright.&nbsp;</p>
            <p><br /></p>
            <p>Except as specifically permitted herein, no portion of the information on this website may be reproduced in any form, or by any means, without prior written permission from the owners.</p>
            <p><br /></p>
            <p>Visitors or users are not permitted to modify, distribute, publish, transmit or create derivative works of any material found on this site for any public or commercial purposes.</p>
            <p><br /></p>
            <p>All imagery contained on the website remains under copyright from the owners.</p>
            <p><br /></p>
            <p>General Disclaimer</p>
            <p><br /></p>
            <p>Contact Information</p>
            <p><br /></p>
            <p>Main Contact</p>
            <p><br /></p>
            <p>Jason Stuart</p>
            <p>Email:&nbsp;<a href="mailto:info@theoutdoornetwork.co.uk">info@theoutdoornetwork.co.uk</a></p>
            <p><br /></p>
            <p>Technical Contact</p>
            <p><br /></p>
            <p>Jon Waite (Advertise1)</p>
            <p>Email:&nbsp;<a href="mailto:hello@advertise1.uk">hello@advertise1.uk</a></p>
            <p><br /></p>
            <p>Address: Advertise1, PROTO, Baltic Business Quarter, Gateshead, Tyne and Wear, NE8 3DF</p>

          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}


export default Terms;
