import { applyMiddleware, createStore } from "redux";
import reducers from "./reducers/index";
import thunk from "redux-thunk";

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const store = createStore(
  reducers,
  //composeEnhancers(applyMiddleware(thunk))
  applyMiddleware(thunk)
);

export default store;