import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import defaultAvatar from "assets/img/placeholder.jpg";
import { Rating } from "react-simple-star-rating";

import SearchAction from "../redux/actions/SearchAction";

import Select from "react-select";
import locationOptions from "../constants/locationOptions";
import activityOptions from "../constants/activityOptions";

// reactstrap components
import {
  CardText,
  Button,
  Form,
  FormGroup,
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
} from "reactstrap";

// core components
import InfoNavbar from "components/InfoNavbar.js";
import FooterWhite from "components/FooterWhite.js";

function Search(props) {
  const { states, searchRequest } = props;
  const search = states.search;
  const history = useHistory();
  const [locationState, setLocationState] = useState(search.search.location);
  const [activityState, setActivityState] = useState(search.search.activity);

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("search-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("search-page");
    };
  });

  function ResultList() {
    if(typeof(search.result) !== "undefined" && search.result.length) {
      var rows = [];
      for (var i = 0; i < search.result.length; i++) {
        rows.push(
          <Col md="6" key={i} href="./Index.js">
            <Card style={{zIndex: 0, padding:12, minHeight:195}}>
              <Row>
                <Col md="5">
                    <a
                      key={i}
                      className=""
                      color="info"
                      href={"/profile/" + search.result[i]._id}
                    >
                <CardImg
                  alt={`Profile picture for ${search.result[i].firstName} ${search.result[i].lastName}`}
                  className="img"
                  src={
                    search.result[i].avatar
                      ? "https://storage.googleapis.com/cloud-storage-ton/" +
                        search.result[i]._id +
                        "-avatar.jpg?" +
                        (Math.random() + 1).toString(36).substring(7)
                      : defaultAvatar
                  }
                  style={{borderRadius: 20}}
                />
                </a>
                </Col>
                <Col md="7">
                  <CardBody className="text-left">
                    <a
                      style={{ color: "#306EB6" }}
                      key={i}
                      className=""
                      color="info"
                      href={"/profile/" + search.result[i]._id}
                    >
                      <CardTitle tag="h4">{search.result[i].firstName} {search.result[i].lastName}</CardTitle>
                    <Rating
                      size={20}
                      initialValue={search.result[i].rating}
                      readonly={true}
                    />    
                    </a>                
                    <CardText className="card-description">
                      {search.result[i].description}
                    </CardText>
                    <a
                      style={{ color: "#306EB6" }}
                      key={i}
                      className=""
                      color="info"
                      href={"/profile/" + search.result[i]._id}
                    >
                      See profile
                    </a>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        );
      }
      return <Row>{rows}</Row>;
    } else {
      return (
        <div className="info">
        <div className="icon icon-warning">
          <i className="nc-icon nc-glasses-2"></i>
        </div>
          <h3>No search results found</h3>
          <h6>Bear with us, we are brand new!!</h6>
          <br></br>

          <p>Our website is brand new so we are light on members at the moment. You could amend your criteria slightly to see if there is someone else nearby.</p>
          <br></br>
          <p>Otherwise, spread the word and make this the home for the outdoor activty network! Share us online</p>
          <p>Please Like our  <a href='https://www.facebook.com'>facebook page</a>, or follow us on <a href='https://www.twitter.com'>Twitter</a>, follow us on <a href='https://www.instagram.com'>Instagram</a></p>  
        </div>
      );
    }
  }

  function handleLocationSearch(value) {
    setLocationState([]);
    setLocationState({ ...locationState, ...{ value } });
  }

  function handleActivitySearch(value) {
    setActivityState([]);
    setActivityState({ ...activityState, ...{ value } });
  }

  return (
    <>
      <InfoNavbar />
      <div className="wrapper">
        <div className="main searchpage-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <Card className="card-raised card-form-horizontal no-transition">
                  <CardBody>
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault();
                        searchRequest(locationState, activityState, history);
                      }}
                    >
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              isClearable={true}
                              name="location"
                              placeholder="Location"
                              onChange={handleLocationSearch}
                              options={locationOptions}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="5">
                          <FormGroup>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              isClearable={true}
                              name="activity"
                              placeholder="Qualification"
                              onChange={handleActivitySearch}
                              options={activityOptions}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <Button block color="info" type="submit">
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="space-top" />
            <ResultList />
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    states: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchRequest: (locationState, activityState, history) => {
      dispatch(SearchAction(locationState, activityState, history));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
