import React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import LoginAuthAction from "../redux/actions/LoginAuthAction"

const Login = (props) => {
  const authLogin = props.authLogin;
  const history = useHistory();
  const { paramJwt } = useParams();

  if (paramJwt) {
    authLogin(paramJwt, history);
  }
  else {
    history.push('/error')
  }
    
    return (
      <>
      </>
    );
}

const mapStateToProps = (state) => {
  return {
    states: state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authLogin: (paramJwt, history) => {
      dispatch(LoginAuthAction(paramJwt, history));
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
