import axios from "axios";
import { AuthActionType } from "../actionTypes";

const LoginAuthAction = (token, history) => {
  return async (dispatch) => {
    try {
      const data = await axios.get("/login/" + token);
      dispatch({ type: AuthActionType.AUTH_SUCCESS, payload: data});
      history.push('/settings')
    } catch (error) {
      dispatch({ type: AuthActionType.AUTH_FAIL, payload: {} });
      history.push('/error')
    }
  };
};

export default LoginAuthAction;