import React from "react";

// core components
import ColorNavbar from "components/ColorNavbar.js";
import LandingPageHeader from "components/LandingPageHeader.js";
import LandingFooterWhite from "components/LandingFooterWhite.js";

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <LandingPageHeader />
      <LandingFooterWhite style={window?.location.pathname === '/index' ? 'bg-transparent' : ''}/>
    </>
  );
}

export default Index;
