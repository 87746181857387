const locations = [
  { value: "Lake_District", label: "Lake District" },
  { value: "Yorkshire_Dales", label: "Yorkshire Dales" },
  { value: "Peak_District", label: "Peak District" },
  { value: "Snowdonia", label: "Snowdonia" },
  { value: "Brecon_Beacons", label: "Brecon Beacons" },
  { value: "Aberdeenshire", label: "Aberdeenshire" },
  { value: "Anglesey", label: "Anglesey" },
  { value: "Angus_Forfarshire", label: "Angus (Forfarshire)" },
  { value: "Antrim", label: "Antrim" },
  { value: "Argyll", label: "Argyll" },
  { value: "Armagh", label: "Armagh" },
  { value: "Avon", label: "Avon" },
  { value: "Ayrshire", label: "Ayrshire" },
  { value: "Banffshire", label: "Banffshire" },
  { value: "Bath_and_North_East_Somerset", label: "Bath and North East Somerset" },
  { value: "Bedford", label: "Bedford" },
  { value: "Bedfordshire", label: "Bedfordshire" },
  { value: "Berkshire", label: "Berkshire" },
  { value: "Berwickshire", label: "Berwickshire" },
  { value: "Betws_y_coed", label: "Betws-y-coed" },
  { value: "Blackburn_with_Darwen", label: "Blackburn with Darwen" },
  { value: "Blackpool", label: "Blackpool" },
  { value: "Bournemouth", label: "Bournemouth" },
  { value: "Bournemouth_Christchurch_and_Poole", label: "Bournemouth, Christchurch and Poole" },
  { value: "Brecknockshire", label: "Brecknockshire" },
  { value: "Brighton_and_Hove", label: "Brighton and Hove" },
  { value: "Bristol", label: "Bristol" },
  { value: "Buckinghamshire", label: "Buckinghamshire" },
  { value: "Bute", label: "Bute" },
  { value: "Caernarfonshire", label: "Caernarfonshire" },
  { value: "Cairngorms", label: "Cairngorms" },
  { value: "Caithness", label: "Caithness" },
  { value: "Cambridgeshire", label: "Cambridgeshire" },
  { value: "Cambridgeshire_and_Isle_of_Ely", label: "Cambridgeshire and Isle of Ely" },
  { value: "Cardiganshire", label: "Cardiganshire" },
  { value: "Carmarthenshire", label: "Carmarthenshire" },
  { value: "Central_Bedfordshire", label: "Central Bedfordshire" },
  { value: "Cheshire", label: "Cheshire" },
  { value: "Cheshire_East", label: "Cheshire East" },
  { value: "Cheshire_West_and_Chester", label: "Cheshire West and Chester" },
  { value: "City_of_Aberdeen", label: "City of Aberdeen" },
  { value: "City_of_Belfast", label: "City of Belfast" },
  { value: "City_of_Derry", label: "City of Derry" },
  { value: "City_of_Dundee", label: "City of Dundee" },
  { value: "City_of_Edinburgh", label: "City of Edinburgh" },
  { value: "City_of_Glasgow", label: "City of Glasgow" },
  { value: "City_of_London", label: "City of London" },
  { value: "Clackmannanshire", label: "Clackmannanshire" },
  { value: "Cleveland", label: "Cleveland" },
  { value: "Clwyd", label: "Clwyd" },
  { value: "Cornwall", label: "Cornwall" },
  { value: "Cromartyshire", label: "Cromartyshire" },
  { value: "Cumberland", label: "Cumberland" },
  { value: "Cumbria", label: "Cumbria" },
  { value: "Darlington", label: "Darlington" },
  { value: "Dartmoor", label: "Dartmoor" },
  { value: "Denbighshire", label: "Denbighshire" },
  { value: "Derby", label: "Derby" },
  { value: "Derbyshire", label: "Derbyshire" },
  { value: "Devon", label: "Devon" },
  { value: "Dorset", label: "Dorset" },
  { value: "Down", label: "Down" },
  { value: "Dumfriesshire", label: "Dumfriesshire" },
  { value: "Dunbartonshire_Dumbarton", label: "Dunbartonshire (Dumbarton)" },
  { value: "Durham_County_Durham", label: "Durham (County Durham)" },
  { value: "Dyfed", label: "Dyfed" },
  { value: "East_Lothian_Haddingtonshire", label: "East Lothian (Haddingtonshire)" },
  { value: "East_Suffolk", label: "East Suffolk" },
  { value: "East_Sussex", label: "East Sussex" },
  { value: "Essex", label: "Essex" },
  { value: "Exmoor", label: "Exmoor" },
  { value: "Fermanagh", label: "Fermanagh" },
  { value: "Fife", label: "Fife" },
  { value: "Flintshire", label: "Flintshire" },
  { value: "Fort_William", label: "Fort William" },
  { value: "Glamorgan", label: "Glamorgan" },
  { value: "Gloucestershire", label: "Gloucestershire" },
  { value: "Greater_London", label: "Greater London" },
  { value: "Greater_Manchester", label: "Greater Manchester" },
  { value: "Gwent", label: "Gwent" },
  { value: "Gwynedd", label: "Gwynedd" },
  { value: "Halton", label: "Halton" },
  { value: "Hampshire", label: "Hampshire" },
  { value: "Hartlepool", label: "Hartlepool" },
  { value: "Hereford_and_Worcester", label: "Hereford and Worcester" },
  { value: "Herefordshire", label: "Herefordshire" },
  { value: "Hertfordshire", label: "Hertfordshire" },
  { value: "Humberside", label: "Humberside" },
  { value: "Huntingdon_and_Peterborough", label: "Huntingdon and Peterborough" },
  { value: "Huntingdonshire", label: "Huntingdonshire" },
  { value: "Inverness-shire", label: "Inverness-shire" },
  { value: "Isle_of_Ely", label: "Isle of Ely" },
  { value: "Isle_of_Wight", label: "Isle of Wight" },
  { value: "Kent", label: "Kent" },
  { value: "Kincardineshire", label: "Kincardineshire" },
  { value: "Kingston_upon_Hull", label: "Kingston upon Hull" },
  { value: "Kinross-shire", label: "Kinross-shire" },
  { value: "Kirkcudbrightshire", label: "Kirkcudbrightshire" },
  { value: "Lanarkshire", label: "Lanarkshire" },
  { value: "Lancashire", label: "Lancashire" },
  { value: "Leicester", label: "Leicester" },
  { value: "Leicestershire", label: "Leicestershire" },
  { value: "Lincolnshire", label: "Lincolnshire" },
  { value: "Loch_Lomond_and_The_Trossachs", label: "Loch Lomond and The Trossachs" },
  { value: "London", label: "London" },
  { value: "Londonderry", label: "Londonderry" },
  { value: "Luton", label: "Luton" },
  { value: "Medway", label: "Medway" },
  { value: "Merionethshire", label: "Merionethshire" },
  { value: "Merseyside", label: "Merseyside" },
  { value: "Mid_Glamorgan", label: "Mid Glamorgan" },
  { value: "Middlesbrough", label: "Middlesbrough" },
  { value: "Middlesex", label: "Middlesex" },
  { value: "Midlothian_County_of_Edinburgh", label: "Midlothian (County of Edinburgh)" },
  { value: "Milton_Keynes", label: "Milton Keynes" },
  { value: "Monmouthshire", label: "Monmouthshire" },
  { value: "Montgomeryshire", label: "Montgomeryshire" },
  { value: "Moray_Elginshire", label: "Moray (Elginshire)" },
  { value: "Nairnshire", label: "Nairnshire" },
  { value: "New_Forest", label: "New Forest" },
  { value: "Norfolk", label: "Norfolk" },
  { value: "Norfolk_Suffolk_Broads", label: "Norfolk/Suffolk Broads" },  
  { value: "North_East_Lincolnshire", label: "North East Lincolnshire" },
  { value: "North_Humberside", label: "North Humberside" },
  { value: "North_Lincolnshire", label: "North Lincolnshire" },
  { value: "North_Somerset", label: "North Somerset" },
  { value: "North_York_Moors", label: "North Yorkshire Moors" },
  { value: "North_Yorkshire", label: "North Yorkshire" },
  { value: "Northamptonshire", label: "Northamptonshire" },
  { value: "Northumberland", label: "Northumberland" },
  { value: "Nottingham", label: "Nottingham" },
  { value: "Nottinghamshire", label: "Nottinghamshire" },
  { value: "Orkney", label: "Orkney" },
  { value: "Oxfordshire", label: "Oxfordshire" },
  { value: "Peeblesshire", label: "Peeblesshire" },
  { value: "Pembrokeshire", label: "Pembrokeshire" },
  { value: "Pembrokeshire_Coast", label: "Pembrokeshire Coast" },
  { value: "Perthshire", label: "Perthshire" },
  { value: "Peterborough", label: "Peterborough" },
  { value: "Peterborough_Soke_of", label: "Peterborough (Soke of)" },
  { value: "Plymouth", label: "Plymouth" },
  { value: "Poole", label: "Poole" },
  { value: "Portsmouth", label: "Portsmouth" },
  { value: "Powys", label: "Powys" },
  { value: "Radnorshire", label: "Radnorshire" },
  { value: "Redcar_and_Cleveland", label: "Redcar and Cleveland" },
  { value: "Renfrewshire", label: "Renfrewshire" },
  { value: "Ross-shire", label: "Ross-shire" },
  { value: "Ross_and_Cromarty", label: "Ross and Cromarty" },
  { value: "Roxburghshire", label: "Roxburghshire" },
  { value: "Rutland", label: "Rutland" },
  { value: "Selkirkshire", label: "Selkirkshire" },
  { value: "Shetland_Zetland", label: "Shetland (Zetland)" },
  { value: "Shropshire", label: "Shropshire" },
  { value: "Somerset", label: "Somerset" },
  { value: "South_Downs", label: "South Downs" },
  { value: "South_Glamorgan", label: "South Glamorgan" },
  { value: "South_Gloucestershire", label: "South Gloucestershire" },
  { value: "South_Humberside", label: "South Humberside" },
  { value: "South_Yorkshire", label: "South Yorkshire" },
  { value: "Southampton", label: "Southampton" },
  { value: "Southend-on-Sea", label: "Southend-on-Sea" },
  { value: "Staffordshire", label: "Staffordshire" },
  { value: "Stirlingshire", label: "Stirlingshire" },
  { value: "Stockton-on-Tees", label: "Stockton-on-Tees" },
  { value: "Stoke-on-Trent", label: "Stoke-on-Trent" },
  { value: "Suffolk", label: "Suffolk" },
  { value: "Surrey", label: "Surrey" },
  { value: "Sussex", label: "Sussex" },
  { value: "Sutherland", label: "Sutherland" },
  { value: "Swindon", label: "Swindon" },
  { value: "Telford_and_Wrekin", label: "Telford and Wrekin" },
  { value: "Thurrock", label: "Thurrock" },
  { value: "Torbay", label: "Torbay" },
  { value: "Tyne_and_Wear", label: "Tyne and Wear" },
  { value: "Tyrone", label: "Tyrone" },
  { value: "Warrington", label: "Warrington" },
  { value: "Warwickshire", label: "Warwickshire" },
  { value: "West_Glamorgan", label: "West Glamorgan" },
  { value: "West_Lothian_Linlithgowshire", label: "West Lothian (Linlithgowshire)" },
  { value: "West_Midlands", label: "West Midlands" },
  { value: "West_Suffolk", label: "West Suffolk" },
  { value: "West_Sussex", label: "West Sussex" },
  { value: "West_Yorkshire", label: "West Yorkshire" },
  { value: "Westmorland", label: "Westmorland" },  
  { value: "Wigtownshire", label: "Wigtownshire" },
  { value: "Wiltshire", label: "Wiltshire" },
  { value: "Worcestershire", label: "Worcestershire" },
  { value: "Wrexham", label: "Wrexham" },
  { value: "York", label: "York" },
  { value: "Yorkshire", label: "Yorkshire" }
]

export default locations;