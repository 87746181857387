import React, { useState }  from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SearchAction from "../redux/actions/SearchAction";

import Select from "react-select";
import locationOptions from "../constants/locationOptions"
import activityOptions from "../constants/activityOptions"

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
} from "reactstrap";

// core components

function LandingPageHeader(props) {
  const {states, searchRequest} = props;
  const search = states.search;

  const [locationState, setLocationState] = useState(search.search.location);
  const [activityState, setActivityState] = useState(search.search.activity);
  const history = useHistory();

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" +
            require("assets/images/background.jpg").default +
            ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 className="title">Find an instructor</h1>
                <h5 className="description">
                Bringing outdoor professionals together
                </h5>
                <br />
              </Col>
              <Col className="ml-auto mr-auto" md="10">
                <div className="">
                  <CardBody>
                    <Form className="search-form" onSubmit={(event) => {
                      event.preventDefault();
                      searchRequest(locationState, activityState, history);
                    }}>
                      <Row>
                        <Col md="4">
                        </Col>
                        <Col md="4">
                          <Button block color="info" type="submit">
                            Search
                          </Button>
                        </Col>
                        <Col md="4">
                        </Col>                        
                      </Row>
                    </Form>
                  </CardBody>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    states: state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchRequest: (locationState, activityState, history) => {
      dispatch(SearchAction(locationState, activityState, history));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageHeader);
