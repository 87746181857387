import React from "react";
import axios from "axios";
import FormData from "form-data";
import { NavLink } from "reactstrap";

import defaultAvatar from "assets/img/placeholder.jpg";

function AvatarUpload(props) {
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState( defaultAvatar);
  const fileInput = React.useRef(null);

  const handleImageChange = async (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      let reader = new FileReader();
      let file = e.target.files[0];
      if (file.type === "image/jpeg" && file.size <= 50 * 1024 * 1024) {
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      try {
        const form = new FormData();
        form.append('file', file);
        const url = "/image/avatar/" + props.id;
        axios.post(url, form, { headers: {
          'Content-Type': 'multipart/form-data'
        }});
        let outdoor_auth = localStorage.getItem("outdoor_auth");
        let outdoor_auth_obj = JSON.parse(outdoor_auth)
        outdoor_auth_obj.user.avatar = true;
        localStorage.setItem("outdoor_auth", JSON.stringify(outdoor_auth_obj));
      }
      catch{}
      }
      else {
        window.alert("Only .jpg images under 50mb.");
      }
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  React.useEffect(() => {
    if(props.avatar) {
      const url = "https://storage.googleapis.com/cloud-storage-ton/" + props.id + "-avatar.jpg?" + (Math.random() + 1).toString(36).substring(7);
      setImagePreviewUrl(url);
    }
  }, [props.avatar, props.id]);

  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail img-circle"}>
        <img style={{objectFit: "cover"}} src={imagePreviewUrl} title="Square images are recommended with at least 150x150 pixel size." alt="Square images are recommended with at least 150x150 pixel size."/>
      </div>
      <div>
            <NavLink 
              className="btn-round btn"
              color="default"
              outline
              onClick={handleClick}>
              {imagePreviewUrl === defaultAvatar ? "Add Photo" : "Change Profile Picture"}
            </NavLink>
      </div>
    </div>
  );
}

export default AvatarUpload;
