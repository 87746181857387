import axios from "axios";
import { AuthActionType } from "../actionTypes";

const ReviewAuthAction = (formData, userId) => {
  return async (dispatch) => {
    try {
      const data = await axios.post("/review/" + userId, formData);
      dispatch({ type: AuthActionType.AUTH_REVIEW_SUCCESS, payload: data});
    } catch (error) {
      dispatch({ type: AuthActionType.AUTH_REVIEW_FAIL, payload: {} });
    }
  };
};

export default ReviewAuthAction;