import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginRequestAuthAction from "../redux/actions/LoginRequestAuthAction";
import SigninRequestAuthAction from "../redux/actions/SigninRequestAuthAction";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import TwitterLogin from "react-twitter-login";

// reactstrap components
import {
  Button,
  Modal,
  Card,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/ColorNavbar.js";

  const Auth = (props) => {
    const history = useHistory();
    const authLoginRequest = props.authLoginRequest;
    const authSigninRequest = props.authSigninRequest;
    const [userState, setUserState] = useState();
    const [smallNotice, setSmallNotice] = React.useState(false);

  const responseFacebook = (response) => {
    if (response.accessToken && response.userID) {
      const token = response.accessToken;
      const userId = response.userID;
      const provider = "facebook";
      authSigninRequest({ provider, token, userId }, history);
    }
  }

  const responseGoogle = async (response) => {
    if (response.tokenId) {
      const token = response.tokenId;
      const provider = "google";
      authSigninRequest({ provider, token }, history);
    }
  }

  const responseTwitter = (err, data) => {
    if (data.oauth_token && data.oauth_token_secret && data.user_id) {
      const oauth_token = data.oauth_token;
      const oauth_token_secret = data.oauth_token_secret;
      const user_id = data.user_id;
      const provider = "twitter";
      authSigninRequest({oauth_token, oauth_token_secret, user_id, provider}, history);
    }
  }

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <div
          className="page-header"
          style={{
            backgroundImage:
              "url(" +
              require("assets/images/background.jpg").default +
              ")",
          }}
        >
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto" lg="6" md="6" sm="7" xs="12">
                <div className="info info-horizontal">
                  <div className="icon">
                    <i className="fa fa-map-signs" />
                  </div>
                  <div className="description">
                    <h3>Expand your network</h3>
                    <p>
                    Not only does your network widen but you will also have access to exclusive discounts, meet
ups and CPD days for your tickets
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon">
                    <i className="fa fa-umbrella" />
                  </div>
                  <div className="description">
                    <h3>We've got you covered</h3>
                    <p>
                    The site is set up to make it easy for companies to find freelancers across the UK in only a
few clicks. Bringing the ‘old school’ pool of freelancers or social media pages into one place.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto" lg="6" md="6" sm="5" xs="12">
                <Card className="card-register">
                  <CardTitle className="text-center" tag="h3">
                    Register / Login
                  </CardTitle>

                  <div className="division">
                    <div className="line l" />
                    
                    <div className="line r" />
                  </div>
                  <Form className="register-form" onSubmit={(event) => {
                    event.preventDefault();
                    authLoginRequest(userState);
                    setSmallNotice(true);
                  }}>
                    <Input
                      placeholder="Email"
                      type="email"
                      name="email"
                      required
                      onChange={(event) => {
                        const email = event.target.value;
                        setUserState({ ...userState, ...{ email }})
                      }}                      
                    />
                    <Button block className="btn" color="default">
                      Login / Register
                    </Button>
                    
                    <br></br>                    
                  </Form>
                </Card>
              </Col>
            </Row>
            <Modal
                size="sm"
                isOpen={smallNotice}
                toggle={() => setSmallNotice(false)}
              >
                <div className="modal-header no-border-header">
                  <button
                    className="close"
                    type="button"
                    onClick={() => setSmallNotice(false)}
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body text-center">
                  <h5 style={{color:'green'}}>Success</h5>
                  <p>
                    We've sent an email to the address you've provided with your link. Please check your junk email folder and add us to your safe sender list.
                  </p>
                </div>
                <div className="modal-footer">
                  <Button
                    color="link"
                    type="button"
                    onClick={() => setSmallNotice(false)}
                  >
                    Okay
                  </Button>
                </div>
              </Modal>
          </Container>
          <div className="demo-footer text-center">
            <h6>
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by The Outdoor Network
            </h6>
          </div>
        </div>
      </div>

      <footer id="new-footer" className="footer">
        <Container className="footer-inner">
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="/terms-and-conditions"
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </nav>

          </Row>
        </Container>
      </footer>

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    states: state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authLoginRequest: (userState) => {
      dispatch(LoginRequestAuthAction(userState));
    },
    authSigninRequest: (userState, history) => {
      dispatch(SigninRequestAuthAction(userState, history));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
