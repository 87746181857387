import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import Auth from "views/Auth.js";
import Error from "views/Error.js";
import Error404 from "views/Error404.js";
import Error422 from "views/Error422.js";
import Index from "views/Index.js";
import Login from "views/Login.js";
import ProfilePage from "views/ProfilePage.js";
import Search from "views/Search.js";
import Settings from "views/Settings.js";
import Terms from "views/Terms.js"
import Privacy from "views/Privacy.js"
import EmailTest from "views/EmailTest.js"
import EmailTest2 from "views/EmailTest2.js"

function App(props) {
  const auth = props.states.auth;
  if (auth.isLoggedIn) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/index" render={(props) => <Index {...props} />} />
          <Route path="/search" render={(props) => <Search {...props} />} />
          <Route path="/profile/:userId" render={(props) => <ProfilePage {...props} />} />
          <Route path="/settings" render={(props) => <Settings {...props} />} />
          <Route path="/terms-and-conditions" render={(props) => <Terms {...props} />} />
          <Route path="/privacy-policy" render={(props) => <Privacy {...props} />} />
          <Route path="/emailtest" render={(props) => <EmailTest {...props} />} />
          <Route path="/emailtest2" render={(props) => <EmailTest2 {...props} />} />
          <Redirect to="/settings" />
        </Switch>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login/:paramJwt" render={(props) => <Login {...props} />} />
          <Route path="/auth" render={(props) => <Auth {...props} />} />
          <Route path="/index" render={(props) => <Index {...props} />} />
          <Route path="/profile/:userId" render={(props) => <ProfilePage {...props} />} />
          <Route path="/search" render={(props) => <Search {...props} />} />
          <Route path="/terms-and-conditions" render={(props) => <Terms {...props} />} />
          <Route path="/privacy-policy" render={(props) => <Privacy {...props} />} />
          <Route path="/emailtest" render={(props) => <EmailTest {...props} />} />
          <Route path="/emailtest2" render={(props) => <EmailTest2 {...props} />} />
          <Route path="/settings" render={(props) => <Settings {...props} />} />
          <Redirect to="/index" />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

console.log("app.js file called on client");