import { AuthActionType } from "../actionTypes";

const LogoutAuthAction = (history) => {
  return async (dispatch) => {
    dispatch({ type: AuthActionType.AUTH_LEAVE, payload: {}});
    history.push('/auth')
  };
};

export default LogoutAuthAction;
