import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SettingAuthAction from "../redux/actions/SettingAuthAction";

import Select from "react-select";
import locationOptions from "../constants/locationOptions";
import activityOptions from "../constants/activityOptions";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CustomInput,
  Modal,
  CardBody,
  CardSubtitle,
} from "reactstrap";

// core components
import AvatarUpload from "components/AvatarUpload.js";
import ColorNavbar from "components/ColorNavbar.js";
import SettingsHeader from "components/SettingsHeader.js";
import FooterWhite from "components/FooterWhite.js";

function Settings(props) {
  const { states, setting } = props;
  const auth = states.auth;
  const [userData, setUserData] = useState(auth.user);
  const [smallNotice, setSmallNotice] = useState(false);
  
  // const [checkedState, setCheckedState] = useState(
  //   new Array(activityOptions.map((v) => userData.activity.find((a) => a.value === v.value)))
  // );  
  
  const isActivityChecked = (userData, activityValue) => {
    if (userData == null || userData.activity == null)
    {
      return false;
    }
    return userData.activity.some((a) => a.value === activityValue);
  }

  const activityUpdate = (activities, checked, activity) => {
    if (activities == null)
    {
      return new Array(activity);
    }
    if (checked) {
      var newActivities = activities.slice();
      newActivities.push(activity);
      return newActivities;
    } else {
      return activities.filter((a) => a.value !== activity.value);
    }
  }


  const AccountTypeToggle = () => {
    return (
      <Row>
        <CardBody
          style={{
            backgroundColor: "#f5f5f5",
            borderRadius: 20,
            marginBottom: 20,
          }}
        >
          <CardSubtitle className="mb2 text-muted">
            If you represent a company select this toggle:
          </CardSubtitle>
          <CustomInput
            type="switch"
            id="exampleCustomSwitch-13"
            name="customSwitch-13"
            className="custom-switch-success"
            // label="Instructor Profile"
            defaultChecked={userData.company}
            onChange={(event) => {
              const company = event.target.checked;
              setUserData({ ...userData, ...{ company } });
            }}
          />
        </CardBody>
      </Row>
    );
  };

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("settings-page");
    return function cleanup() {
      document.body.classList.remove("settings-page");
    };
  });

  // React.useEffect(()=> {
  //   if (typeof(userData.instructor) === "undefined") {
  //     const instructor = true;<
  //     setUserData({...userData, ...{ instructor }});
  //   }
  // }, [userData.instructor, userData]);

  useEffect(() => {
    if (typeof userData.company === "undefined") {
      const company = false;
      setUserData({ ...userData, ...{ company } });
    }
  }, [userData.company, userData]);

  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <SettingsHeader id={auth.user._id} hero={auth.user.hero} />
        <div className="profile-content section">
          <Container>
            <Form
              className="settings-form"
              onSubmit={(event) => {
                event.preventDefault();
                setting(userData);
                setSmallNotice(true);
              }}
            >
              <Row>
                <div className="profile-picture">
                  <AvatarUpload id={auth.user._id} avatar={auth.user.avatar} />
                </div>
              </Row>

              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <AccountTypeToggle />
                </Col>
              </Row>

              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  {!userData.company ? (
                    <Row>
                      <Col md="6" sm="6">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            className="border-input"
                            placeholder="First Name"
                            type="text"
                            name="first-name"
                            value={userData.firstName}
                            onChange={(event) => {
                              const firstName = event.target.value;
                              setUserData({
                                ...userData,
                                ...{ firstName },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="6">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            className="border-input"
                            placeholder="Last Name"
                            type="text"
                            name="last-name"
                            value={userData.lastName}
                            onChange={(event) => {
                              const lastName = event.target.value;
                              setUserData({
                                ...userData,
                                ...{ lastName },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {userData.company ? (
                    <FormGroup>
                      <label>Company Name</label>
                      <Input
                        className="border-input"
                        placeholder="Your Company ltd."
                        type="text"
                        name="company-name"
                        value={userData.companyName}
                        onChange={(event) => {
                          const companyName = event.target.value;
                          setUserData({
                            ...userData,
                            ...{ companyName },
                          });
                        }}
                      />
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  <FormGroup>
                    <label>Email Address</label>
                    <Input
                      className="border-input"
                      type="text"
                      value={userData.email}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <label>Tell Us About You</label>
                    <Input
                      placeholder="Use this box to tell us about yourself."
                      className="textarea-limited"
                      type="textarea"
                      rows="3"
                      name="description"
                      value={userData.description}
                      onChange={(event) => {
                        const description = event.target.value;
                        setUserData({
                          ...userData,
                          ...{ description },
                        });
                      }}
                    />
                  </FormGroup>

                  {!userData.company ? (
                    <>
                      <FormGroup>
                        <label>Locations</label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          isMulti
                          name="location"
                          defaultValue={userData.location}
                          onChange={(value) => {
                            setUserData({
                              ...userData,
                              ...{ location: value },
                            });
                          }}
                          options={locationOptions}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label>Select your Qualifications:</label>
                        <ul>
                          {activityOptions.map(({ type, label, value }, index) => {
                            return (
                              <p key={index}>
                                <div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      defaultChecked={isActivityChecked(userData, value)}
                                      id={`custom-checkbox-${index}`}
                                      name={label}
                                      value={value}
                                      class="form-check-input"
                                      onChange={(event) => {
                                        setUserData({
                                          ...userData,
                                          ...{ activity: activityUpdate(userData.activity, event.target.checked, {label: label, value: value})
                                           },
                                        });
                                      }}
                                    />
                                    <label htmlFor={`custom-checkbox-${index}`}>
                                      {label}
                                    </label>
                                  </div>
                                </div>
                              </p>
                            );
                          })}
                        </ul>
                      </FormGroup>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="text-center">
                    <Button className="btn-wd btn" color="info" type="submit">
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
            <Modal
              size="sm"
              isOpen={smallNotice}
              toggle={() => setSmallNotice(false)}
            >
              <div className="modal-header no-border-header">
                <button
                  className="close"
                  type="button"
                  onClick={() => setSmallNotice(false)}
                >
                  ×
                </button>
                <h5 class="modal-title" id="myModalLabel">
                  Success
                </h5>
              </div>
              <div className="modal-body text-center">
                <p>Settings successfully saved.</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="link"
                  type="button"
                  onClick={() => setSmallNotice(false)}
                >
                  Okay
                </Button>
              </div>
            </Modal>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    states: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setting: (userData) => {
      dispatch(SettingAuthAction(userData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
