import React, {useEffect} from "react";

import defaultHeader from "assets/img/sections/joshua-earles.jpg";

// core components

function ProfilePageHeader(props) {
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState( defaultHeader);

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  useEffect(() => {
    if(props.hero) {
      const url = "https://storage.googleapis.com/cloud-storage-ton/" + props.id + "-hero.jpg?" + (Math.random() + 1).toString(36).substring(7);
      setImagePreviewUrl(url);
    }
  }, [props.hero, props.id]);

  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" + imagePreviewUrl + ")",
        }}
      >
        <div className="filter" />
      </div>
    </>
  );
}

export default ProfilePageHeader;
